import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import AvImgurImage from '../components/AvImgurImage'
import axios from 'axios';

class AvGalleryDisplay extends React.Component {

  constructor(props) {
    super(props);

    this.chunkArray = this.chunkArray.bind(this);
    this.galleryDisplay = this.galleryDisplay.bind(this);
    this.getPageDisplay = this.getPageDisplay.bind(this);
    this.getHomeDisplay = this.getHomeLatestDisplay.bind(this);

    this.state = {
      galleryDisplayURLs: [],
      allURLs: []
    };
  }

  chunkArray(myArray, chunk_size) {
    var results = [];
    while (myArray.length) {
        results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }

setGalleryDisplayPhotos = (posts) => {
  var URLs = [];
  for (var i = 0; i < posts.length; i++) {
    var postImgURL = posts[i].link;
    URLs[i] = postImgURL;
  }

  this.setState({ allURLs: URLs});
  console.log(this.state.allURLs);
};

componentDidMount() {
  axios.get('https://api.imgur.com/3/account/artventures/images',
    {headers: {'Authorization': 'Bearer c2380901876d59d07977ad413f96b29e3a47773b'}})
      .then(res => {
        var imgData = res.data.data;
        this.setGalleryDisplayPhotos(imgData);
      })
}

getPageDisplay() {
  return (
    <div>
      {this.chunkArray(this.state.allURLs, 4).map(function(URLarray) {
        return(
          <Row className="d-flex align-items-center galleryRows">
            <AvImgurImage imgURL={URLarray[0]} />
            <AvImgurImage imgURL={URLarray[1]} />
            <AvImgurImage imgURL={URLarray[2]} />
            <AvImgurImage imgURL={URLarray[3]} />
          </Row>
        )
      })}
    </div>
  );
}

getHomeLatestImage(idx) {
  return(
    <Col lg="6" sm="6" md="6" xs="6" className="">
      <a href="/gallery"><img id="" src={idx} className="img-fluid" /></a>
    </Col>
  )
}

getHomeLatestDisplay() {
  return (
    <div>
      <Row className="d-flex justify-content-center">
        <Col className="text-center">
          <h1>The Latest and Greatest</h1>
        </Col>
      </Row>
      <Row className="d-flex align-items-center galleryRows">
        {this.getHomeLatestImage(this.state.allURLs[0])}
        {this.getHomeLatestImage(this.state.allURLs[1])}
      </Row>
      <Row className="d-flex align-items-center">
        {this.getHomeLatestImage(this.state.allURLs[2])}
        {this.getHomeLatestImage(this.state.allURLs[3])}
      </Row>
    </div>
  );
}

galleryDisplay(galleryDisplayType) {
  if (galleryDisplayType === 'home') {
    return this.getHomeLatestDisplay();
  } else {
    return this.getPageDisplay();
  }
}

render() {
		return (
      <div>{this.galleryDisplay(this.props.displayType)}</div>
		);
	}
}

export default AvGalleryDisplay;
