import React from 'react';
import { Container, Row, Col, Button, Jumbotron } from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import avCircleLogo from "../images/avCircleLogo.svg"

function sectionSocialLinks() {
  return (
    <div>
      <Row id="socialRow">
        <Col className="d-flex align-items-center justify-content-center">
          <a href="https://www.facebook.com/ArtVentures-138500606224822/"><FontAwesomeIcon size="4x" icon={['fab', 'facebook']} /></a>
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <a href="https://www.instagram.com/artventuresframing/"><FontAwesomeIcon size="4x" icon={['fab', 'instagram']} /></a>
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <a href="https://www.twitter.com/artventures_"><FontAwesomeIcon size="4x" icon={['fab', 'twitter']} /></a>
        </Col>
      </Row>
    </div>
  );
}


class AvContactFooter extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Container id="contact" className="anchorContainer" fluid>
				<span id="contactAnchor" className="anchorSpan"></span>
				<Row className="d-flex align-items-center">
					<Col className="text-center">
						<img id="contactLogo" src={avCircleLogo} className="img-fluid" />
						<p className="lead"><a href="https://maps.google.com/?q=ArtVentures Brighton, MI, 48116">422 W Main St Brighton, MI 48116</a><br/>
						phone: <span itemprop="telephone"><a href="tel:+18102293100">
810-229-3100</a></span> <br/>
						<a href="mailto:artventuresframing@gmail.com">artventuresframing@gmail.com</a></p>
					</Col>
				</Row>
				{sectionSocialLinks()}
			</Container>
		);
	}
}

export default AvContactFooter;
