import React from 'react';
import { Navbar, Collapse, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { Link } from "gatsby"
import avCircleLogo from "../images/avCircleLogo.svg"

class AvNav extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.closeNavbar = this.closeNavbar.bind(this);
		this.state = {
		  isOpen: false
		};
	}
	toggle() {
		this.setState({
		  isOpen: !this.state.isOpen
		});
	}
	closeNavbar() {
		if (this.state.isOpen === true) {
		  this.toggle();
		}
	}

	render() {
		const navMenuItems = [];
	  this.props.navItems
	    .forEach(navMenuItem => navMenuItems.push(
														<NavItem onClick={this.closeNavbar}>
															<NavLink href={navMenuItem.location}>{navMenuItem.text}</NavLink>
													 	</NavItem>
	                     ))
		return (
				<Navbar expand="md" className="navbar fixed-top navbar-light bg-light shadow-sm">
					<NavbarBrand onClick={this.closeNavbar} href="/">
						<img id="navbarLogo" src={avCircleLogo} className="img-fluid" />
					</NavbarBrand>
					<div id="brandCallUs">
						<a href="tel:+18102293100"><FontAwesomeIcon size="lg" icon={['fas', 'phone']} /></a>
					</div>
					<div id="brandMapUs">
						<a href="https://maps.google.com/?q=ArtVentures Brighton, MI, 48116"><FontAwesomeIcon size="lg" icon={['fas', 'map-marked-alt']} /></a>
					</div>
					<NavbarToggler onClick={this.toggle} />
					<Collapse isOpen={this.state.isOpen} navbar>
					<Nav navbar>
						{navMenuItems}
					</Nav>
					<Nav navbar className="ml-auto">
						<NavItem>
							<NavLink href="https://www.facebook.com/ArtVentures-138500606224822/"><FontAwesomeIcon size="lg" icon={['fab', 'facebook']} /></NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="http://www.instgram.com/artventuresframing/"><FontAwesomeIcon size="lg" icon={['fab', 'instagram']} /></NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="http://www.twitter.com/artventures_"><FontAwesomeIcon size="lg" icon={['fab', 'twitter']} /></NavLink>
						</NavItem>
					</Nav>
					</Collapse>
				</Navbar>
		);
	}
}

export default AvNav;
