import React from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';

class AvImgurImage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

		this.toggle = this.toggle.bind(this);
  }

  toggle() {
	 this.setState({
		 modal: !this.state.modal
	 });
 	}

	render() {
		return (
      <Col lg="3" sm="6" md="6" xs="6" className="galleryImgurImage">
        <img onClick={this.toggle} src={this.props.imgURL} className="img-fluid" />
        <Modal isOpen={this.state.modal} id={this.props.imgURL} toggle={this.toggle} className={this.props.className} >
          <ModalBody>
            <img id="" src={this.props.imgURL} className="img-fluid" />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Close</Button>{' '}
          </ModalFooter>
        </Modal>
      </Col>
		);
	}
}

export default AvImgurImage;
